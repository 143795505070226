import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
const CollaborateurComp = ({ id, dynamicData }) => {
  if (dynamicData) {
    let {
      name,
      description,
      discipline,
      portfolio,
      videos,
      siteWeb,
      instagram,
      vimeo,
      facebook,
      behance,
    } = dynamicData.find(c => c.id === id)

    return (
      <section className="collaborateur">
        <h1>{name}</h1>
        {discipline && (
          <ul className="collaborateur__disciplines">
            {discipline.map(d => (
              <li key={d} className="collaborateur__discipline">
                {d}
              </li>
            ))}
          </ul>
        )}
        {description && (
          <div className="collaborateur__description">
            {documentToReactComponents(description)}
          </div>
        )}
        <div className="collaborateur__social-links">
          {siteWeb && (
            <a className="collaborateur__social-link" href={siteWeb}>
              Web
            </a>
          )}
          {instagram && (
            <a className="collaborateur__social-link" href={instagram}>
              Instagram
            </a>
          )}
          {facebook && (
            <a className="collaborateur__social-link" href={facebook}>
              Facebook
            </a>
          )}
          {vimeo && (
            <a className="collaborateur__social-link" href={vimeo}>
              Vimeo
            </a>
          )}
          {behance && (
            <a className="collaborateur__social-link" href={behance}>
              Behance
            </a>
          )}
        </div>
        {portfolio && (
          <div>
            {portfolio.map(p => (
              <img key={p} src={p} alt={`${name}'s portfolio item`} />
            ))}
          </div>
        )}
        {videos && (
          <div>
            {videos.map((v, i) => (
              <div className="videoContainer" key={i}>
                {v.vimeoUrl && (
                  <iframe
                    title={`vimeo iframe ${v.vimeoUrl}`}
                    src={v.vimeoUrl}
                    width={window ? window.innerWidth * 0.8 : 1}
                    height={window ? (window.innerWidth / 16) * 9 * 0.8 : 1}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullcreen
                  ></iframe>
                )}
                {v.youtubeUrl && (
                  <iframe
                    title={`youtube iframe ${v.youtubeUrl}`}
                    width={window ? window.innerWidth * 0.8 : 1}
                    height={window ? (window.innerWidth / 16) * 9 * 0.8 : 1}
                    src={v.youtubeUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
                <script
                  crossOrigin
                  src="https://player.vimeo.com/api/player.js"
                ></script>
              </div>
            ))}
          </div>
        )}
      </section>
    )
  }

  return <div>Chargement</div>
}

export default CollaborateurComp
