import React, { useContext, useState, useEffect } from "react"
import { Link } from "gatsby"
import { LangContext } from "../hooks/lang.js"

const CollaborateursComp = ({ dynamicData }) => {
  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null

  let french = langContext && langContext.lang && langContext.lang === "fr-CA"
  let [ hovering, setHovering ] = useState(false)
  let [ phareUrl, setPhareUrl ] = useState(null)
  let [ pharePosition, setPharePosition ] = useState(null)
  const showPhare = e => {
    e.persist()
    e.preventDefault()
    if (e.target.dataset.phareurl && typeof window !== undefined) {
      setHovering(true)
      setPhareUrl(e.target.dataset.phareurl)

      let newPos = {
        left: `${window.innerWidth * Math.random()}`,
        top: `${window.innerHeight * Math.random()}`,
      }

      if (newPos.left < (window.innerWidth / 100) * 5) {
        newPos.left = (window.innerWidth / 100) * 5
      }
      if (newPos.left > (window.innerWidth / 100) * 65) {
        newPos.left = (window.innerWidth / 100) * 65
      }

      if (newPos.top < (window.innerHeight / 100) * 10) {
        newPos.top = (window.innerHeight / 100) * 10
      }
      if (newPos.top > (window.innerHeight / 100) * 40) {
        newPos.top = (window.innerHeight / 100) * 35
      }
      newPos.left = parseInt(newPos.left) + "px"
      newPos.top = parseInt(newPos.top) + "px"

      setPharePosition(newPos)
    } else {
      setPhareUrl(null)
    }
  }
  const hidePhare = e => {
    setHovering(false)
    setPhareUrl(null)
  }

  let [ dynamicPageData, setDynamicPageData ] = useState(null)
  useEffect(() => {
    window.contentfulClient
      .getEntries({
        content_type: "pageCollaborateur",
        locale: lang,
      })
      .then(p => {
        let dd = p.items[ 0 ].fields
        setDynamicPageData(dd)
      })
  }, [ lang ])
  if (dynamicPageData && dynamicData) {
    return (
      <section className="collaborateurs">
        <h2 className="collaborateurs__header">{dynamicPageData.header}</h2>
        <ul className="collaborateurs__list">
          {dynamicData.map(c => (
            <li key={c.imagePhare || Math.random()}>
              <Link
                data-phareurl={c.imagePhare}
                onMouseEnter={showPhare}
                onMouseLeave={hidePhare}
                className="collaborateur__link"
                to={`/collaborateur/${c.id}`}
                state={{ collaborateur: c }}
              >
                {c.name}
              </Link>
            </li>
          ))}
        </ul>
        {hovering && (
          <img
            name={phareUrl}
            className="phare"
            src={phareUrl}
            alt="sample from collaborator's work"
            style={pharePosition}
          />
        )}
      </section>
    )
  } else {
    return <div>{french ? "Chargement" : "Loading"}</div>
  }
}

export default CollaborateursComp
