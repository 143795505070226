import React, { useContext, useEffect, useState } from "react"
import { Router } from "@reach/router"
import Page from "../components/page"
import { LangContext } from "../hooks/lang.js"

import CollaborateursComp from "../components/Collaborateurs.js"
import CollaborateurComp from "../components/Collaborateur.js"

const Collabs = props => {

  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null
  let [ dynamicData, setDynamicData ] = useState(null)
  useEffect(() => {
    window.contentfulClient
      .getEntries({ content_type: "collaborateur", locale: lang })
      .then(p => {
        let cleanOne = p.items.map(c => ({ ...c.fields, id: c.sys.id }))
        let dd = cleanOne.map(c => ({
          ...c,
          imagePhare: (c.imagePhare && c.imagePhare.fields.file.url) || null,
          discipline:
            (c.discipline && c.discipline.map(d => d.fields.titre)) || null,
          portfolio:
            (c.portfolio && c.portfolio.map(d => d.fields.file.url)) || null,
          videos:
            (c.videos &&
              c.videos.map(d => ({
                youtubeUrl: d.fields.youtubeUrl,
                vimeoUrl: d.fields.vimeoUrl,
              }))) ||
            null,
        }))

        dd = dd.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase()
          if (nameA < nameB)
            //sort string ascending
            return -1
          if (nameA > nameB) return 1
          return 0 //default return value (no sorting)
        })
        setDynamicData(dd)

        dd.forEach(d => {
          const img = new Image()
          img.src = d.imagePhare
        })
      })
  }, [ lang ])
  return (
    <Router>
      <CollaborateursComp dynamicData={dynamicData} path="/collaborateurs/" />
      <CollaborateurComp dynamicData={dynamicData} path="/collaborateur/:id" />
    </Router>
  )
}

const CollaborateursWithPage = props => (
  <Page>
    <Collabs {...props} />
  </Page>
)

export default CollaborateursWithPage
